import './App.css';
import YoutubeEmbed from './YoutubeEmbed';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div class="container">
      <div class="banner">
        <h4 class="title">
          <span>MARK BIXLER COMEDY</span>
        </h4>
      </div>
      <div class="spanner"></div>
      <div class="left">
        <div class="hs-container">
          <img
            src="../_D704363.jpg"
            class="responsive-hs"
            alt="MarkHeadshot"
          ></img>
        </div>
        <div>
          <p class="review-header-container">
          <span class="review-header">...what people are saying:</span>
          </p>
          <div>
            <p class="reviews">
              <span class="quote"><FontAwesomeIcon icon={faQuoteLeft} /></span>
              <span class="review">Mark Bixler provided comedy for our staff and elder Christmas
              party and he was hilarious! He got us all laughing that night and
              for many days to follow as we have remembered, referenced and
              retold the stories he started that night. If you want to laugh
              together, I recommend Mark to you for your next event."</span>
              <br /> - Susie Grade (Owner: Sipping n' Painting / Highland)
            </p>
            <p class="reviews">
              <span class="quote"><FontAwesomeIcon icon={faQuoteLeft} /></span>
              <span class="review">Our event was so impressed with Mark Bixler at our film screening
              that I actually hired him again for another two separate events. 
              Mark Bixler was the perfect addition, he was fun and funny, he had
              a fascinating one man, stand-up comedy act, and he was simply a
              hit with the audience.  His material was ideally suited for our
              professional crowd.  I should add that this event turned out to be
              our highest attended event ever.  I gave Mark credit for drawing
              such a large crowd.  As a professional award winning actor,
              producer, director, writer who doesn't put his signature onto just
              anything or anyone I give my highest recommendation to hire Mark
              Bixler as your entertainment, you will not be disappointed!"{' '}
              </span><br />
              -Owen Hisle (Award Winning Actor, Producer, Director, Writer)
            </p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="youtube">
          <div className="videos">
            <YoutubeEmbed embedId="k7HhblTXQIc" />
          </div>
          <div className="videos">
            <YoutubeEmbed embedId="dMZX-241eiI" />
          </div>
          <div className="videos">
            <YoutubeEmbed embedId="lNdDfMnmSe0" />
          </div>
          <div className="videos">
            <YoutubeEmbed embedId="0mkAAInRyU0" />
          </div>
          <div className="videos">
            <YoutubeEmbed embedId="oE3Mk7x_lj0" />
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  );
}

export default App;
